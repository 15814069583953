.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
}

.header.container {
  padding-bottom: 8px;
  min-height: 0;
}

.header > img {
  height: 35px;
  display: block;
}

.header > h3 {
  margin:0;
}

.header > h3, .header > i {
  font-size:1.6em;
}
