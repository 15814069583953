h1 {
  margin-top: 50px;
  text-align: center;
}

.login {
  margin: auto;
  margin-top: 150px;
  max-width: 550px;
  position: relative;
}
.login-form {
  position: inherit;
  width: 100%;
  margin-top: 50px;
}
.login-form > * {
  margin-top: 20px;
}

@media(max-width: 450px) {
  .login {
    padding-right: 20px;
    padding-left: 20px;
    width:100%;
  }
}