.text-field {
  width: 100%;
}
/* .text-field > input {
  background: #f0f0f0;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #707070;
  font-size: 1.2em;
  outline: 0;
  padding: 16px;
  width: inherit;
} */

