.qtd-field {
  padding: 0;
  min-width: 200px;
  position: relative;
}
.qtd-field > input {
  border: 1px solid #777;
  height: 100%;
  padding: 0 26%;
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
}
.qtd-field > span {
  align-items: center;
  background: #777;
  color: #fff;
  cursor: pointer;
  position: absolute;
  display:flex;
  flex-direction: column;
  justify-content: center;
  width: 25%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.qtd-field > span:last-child {
  left: unset;
  right: 0;
}
