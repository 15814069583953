.datetime-picker-field {
  max-width:350px;
}

.react-datepicker {
  font-size: 1.8rem;
}

.react-datepicker__header {
  padding-top: 1.6rem;
}

.react-datepicker__month {
  margin: 1.4rem 2rem;
}

.react-datepicker__day-name,
.react-datepicker__day {
  width: 3.4rem;
  line-height: 2.6rem;
  margin: 0.1rem;
}

.react-datepicker__current-month {
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.react-datepicker__navigation {
  top: 1.2rem;
  line-height: 1.0rem;
  border: 1.1rem solid transparent;
}

.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1rem;
}

.react-datepicker__navigation--next {
  border-left-color: #ccc;
  margin-right: 30px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 16px;
  color: #000;
  font-weight: bold;
  font-size: 1.6rem;
}

.react-datepicker__header--time {
  padding-bottom: 30px;
  padding-left: 5px;
  padding-right: 5px;

}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 100px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 95px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 50px;
  width: 98px;
  padding: 10px 0px 50px;
}


.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}