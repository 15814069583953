.products-list .search {
  border-bottom: 1px solid #777;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right:4.5rem;
  position: relative;
}
.products-list .search > i {
  position: absolute;
  right:0;
  top:50%;
  transform: translateY(-50%);
  font-size: 3.0rem;
}
.product-list-item > .left-item > p {
  color: #444;
  font-size: 1.8rem;
}
.products-list .products-found {
  min-height: 80px;
}
/* .products-found > h3 {
  color:#a0a0a0;
  text-align: center;
  margin:20px 0;
} */