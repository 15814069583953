.loading {
  align-items: center;
  background: #e0e0e0a0;
  top: 0;
  left: 0;
  display: flex;
  font-size: 3.4rem;
  flex-direction: column;
  justify-content: center;
  margin: 0 !important;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.loading i {
  cursor: default;
}
