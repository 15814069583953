.finish-order {
  margin-top: 20px;
  text-align: center;
}
.finish-order > a {
  display: block;
  font-size: 2.4rem;
}
.order-list-item>button {
  width: 161px;
  height: 170px;
  margin-right: 20px;
}