.home {
  background: #efefef;
  padding: 15px;
}
.home > .header-ritz {
  justify-content: center;
  height: 130px;
}
.home > .sub-container {
  background: #fff;
  padding: 10px;
}

.home > .sub-container > h3 {
  margin: 20px 0;
}

/* .home form > .text-field + .text-field,
.home form > .text-field + .datetime-picker-field,
.home form > .datetime-picker-field + .datetime-picker-field,
.home form > .datetime-picker-field + .text-field
{
  margin-top: 15px;
} */
.home form {
  padding-bottom: 3rem;
}
.home form > button {
  margin-top: 2.5rem;
  padding: 1.25rem 0;
}

.home > * {
  color: inherit;
}

#tipo_cliente_field {
  max-width: 440px;
}
