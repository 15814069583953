.order-list-item {
  align-items: center;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5px;
  width: 100%;
}
.order-list-item + .order-list-item {
  border-top: 1px solid #444;
}
/* .order-list-item > .left-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
} */
.order-list-item > img {
  margin-right: 10px;
  width: 150px;
}
.order-list-item > p {
  color: #444;
  font-size: 2.4rem;
}
.order-list-item > button {
  width: 120px;
  height: 170px;
  margin-right: 20px;
}
.order-list-item .center-item {
  width: 50%;
}
.order-list-item .center-item > p {
  font-size: 2.0rem;
}
.order-list-item .qtd-field {
  min-width: 100px;
}
.order-list-item .qtd-field > input {
  font-size: 2.4rem;
}
.order-list-item .center-item > small {
  display: inline-block;
  margin-left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  font-size: x-large;
}
@media (max-width: 490px) {
  .order-list-item .center-item > p {
    font-size: 1rem;
  }
  .order-list-item > img {
    margin-right: 5px;
    width: 80px;
  }
}
