.slide-msg-background {
  background: #e0e0e0aa;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  height: 100%;
  width: 100%;
  z-index: 30;
}
.slide-msg {
  background: #fff;
  border: 1px solid #f80;
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  top: -10rem;
  height: 10rem;
  width: 100%;
  z-index: 35;
  transition: all 0.5s ease;
}

.slide-msg > h3 {
  text-align: center;
  margin: 1.5rem 0;
}

.slide-msg > button.btn-default {
  width: 35%;
  margin: auto;
}
.slide-msg-background.show {
  display: block;
}
.slide-msg.show {
  top: 1px;
}
