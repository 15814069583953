.products-details {
  height: 100%;
  padding-bottom: 90px;
}
.imgContainer {
  position: relative;
  text-align: center;
  color: rgb(240, 20, 20);
  font-size: 1.8rem;
}
.bottom-right {
  position: absolute;
  bottom: 14px;
  right: 16px;
}
.product-details-img {
  width: 100%;
}
.product-details1 {
  background: #f0f0f0;
  color: #777;
  font-size: 2rem;
  position: relative;
}
.product-details1 > div:not(.loading) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top:20px;
  padding-bottom:18px;
}
.product-details1 > div > * {
  font-weight: bold;
}
.product-details1 > div + div {
  border-top: 1px solid #777;
}
.product-details1 p {
  margin:0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-details1 .btn-default {
  width: 250px;
}
.product-details1 p.color-ritz {
  font-size: 2.8rem;
}

.product-details-controls, .product-details-controls > button {
  font-size:2.4rem;
  margin-top: 5px;
}
.product-details-controls .qtd-field {
  flex:0;
  font-size:2.4rem;
  min-width: 250px;
}
.qtd-field > input {
  border: 1px solid #777;
  height: 100%;
  padding: 0 26%;
  width: 100%;
  font-size: 2.4rem;
  text-align: center;
}