.menu {
  width: 100%;
  height: 96px;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 10;
}

.menu > ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  margin: 0;
}

.menu > ul > li {
  align-items: center;
  padding: 0 20px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid #f0f0f0;
  flex-basis: 100%;
}

.menu > ul > li:last-child {
  border-right: 1px solid #f0f0f0;
}

.menu > ul > li i {
  color: #fff;
  font-size: 4rem;
}

/* cover menu */
.cover-menu {
  background: #808080b0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}