.header-ritz {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.header-ritz > img {
  display: block;
  height: 80%;
}

@media(max-width:450px) {
  .header-ritz {
    height: 80px;
  }
}