* {
  box-sizing: border-box;
}
html {
  font-size: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  display: inline-block;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Globals */
button,
i {
  cursor: pointer;
  outline: 0;
}

/* Boxes */
.container {
  max-width: 768px;
  margin: auto;
  padding-bottom: 90px;
  width: 100%;
}
.container-padding {
  padding-right: 0.7rem;
  padding-left: 0.7rem;
}
.padding-menu-bottom {
  padding-bottom: 90px;
}

/* BTNs */
.btn-default {
  background: #31315b;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 2.4rem;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

/* selects */
.select-default {
  background: #fff;
  width:100%;
  padding: 8px 10px;
  border: 1px solid #c0c0c0;
  font-size: 1.4rem;
}

/* visibility */
.hide {
  display: none !important;
}

/* forms */
.form-group {
  width: 100%;
}
.form-group + .form-group {
  margin-top: 1rem;
}

/* defaults */
.color-ritz {
  color: #31315b;
}
.background-ritz {
  background: #31315b;
}
.border-ritz {
  border-color: #31315b;
}
.label {
  color: #505050;
  display: block;
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 5px;
}
.default-error-msg {
  color: #a00000;
  display: block;
  font-weight: bold;
  margin-top: 5px;
}

.default-text-field,
.react-datepicker-wrapper .react-datepicker__input-container > input {
  background: #f0f0f0;
  border: 1px solid #707070;
  border-radius: 5px;
  color: #707070;
  font-size: 2.4em;
  outline: 0;
  padding: 16px;
  width: inherit;
}



/* Not found items */
.default-msg1 {
  color: #a0a0a0;
  font-size: 1.8rem;
  text-align: center;
  margin: 20px 0;
}

/* medias */
@media (max-width: 490px) {
  html {
    font-size: 80%;
  }

  .btn-default {
    font-size: 0.9rem;
  }
}
