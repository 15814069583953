p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

}

#footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background: #999;
  text-align: center;
}
