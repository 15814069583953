.product-list-item {
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 5px;
  width: 100%;
}
.product-list-item + .product-list-item {
  border-top: 1px solid #444;
}
.product-list-item > .left-item {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex: 1;
  margin-inline-end: 11px;
}
.product-list-item > .left-item > img {
  margin-right: 10px;
  width: 150px;
}

.product-list-item > .right-item {
  align-items: center;
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

@media(max-width: 450px) {
  .product-list-item > .left-item > p {
    font-size: 1rem;
  }
  .product-list-item > .left-item > img {
    margin-right: 5px;
    width: 80px;
  }
}